var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"permissions":['USERS_ADMINISTRATION', 'PD_SUPPORT']},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"background-color":"#464646","flat":"","hide-details":"auto","label":"userId / username / email / clickId","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/findUsers.gql'),"variables":{
          pageIndex: (_vm.page - 1) * _vm.itemsPerPage,
          pageSize: _vm.itemsPerPage,
          search: _vm.search,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ref_result = ref.result;
        var usersError = ref_result.error;
        var usersData = ref_result.data;
        var usersIsLoading = ref.isLoading;
return [(usersError)?_c('AppNotification',{attrs:{"message":usersError.message}}):(usersData || usersIsLoading === 1)?_c('v-card',{staticClass:"px-4 pb-3",attrs:{"loading":usersIsLoading === 1,"flat":""}},[(usersData)?[_c('v-card-title',{staticClass:"py-7"},[_vm._v(" Пользователи: "+_vm._s(usersData.findUsers.count)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":usersData.findUsers.users,"items-per-page":_vm.itemsPerPage,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.img",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.img ? '' : '#c4c4c4',"size":"36"}},[(item.img)?_c('img',{attrs:{"src":item.img}}):_c('v-icon',{staticStyle:{"height":"inherit"},attrs:{"size":"22"}},[_vm._v(" mdi-account ")])],1)]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY, HH:mm'))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"to":{ name: 'users', params: { id: item.id }},"depressed":"","height":"36","large":""}},[_vm._v(" Подробнее ")])]}}],null,true)}),_c('v-divider'),_c('div',{staticClass:"text-center pt-3"},[_c('v-pagination',{attrs:{"length":usersData.findUsers.pages,"total-visible":9},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-select',{staticClass:"pa-4 pt-5",attrs:{"items":[5, 10, 15],"label":"Строк на странице"},on:{"change":_vm.firstPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)]:_vm._e()],2):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }