<template>
  <AppRow :permissions="['USERS_ADMINISTRATION', 'PD_SUPPORT']">
    <template v-slot>
      <v-col cols="12">
        <v-text-field
          v-model="searchText"
          background-color="#464646"
          class="rounded-lg"
          flat
          hide-details="auto"
          label="userId / username / email / clickId"
          solo
        >
          <template v-slot:prepend-inner>
            <v-icon class="mr-1">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col>
        <ApolloQuery
          :query="require('../graphql/queries/findUsers.gql')"
          :variables="{
            pageIndex: (page - 1) * itemsPerPage,
            pageSize: itemsPerPage,
            search,
          }"
        >
          <template v-slot="{ result: { error: usersError, data: usersData }, isLoading: usersIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="usersError"
              :message="usersError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="usersData || usersIsLoading === 1"
              :loading="usersIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <template v-if="usersData">
                <v-card-title class="py-7">
                  Пользователи: {{ usersData.findUsers.count }}
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="usersData.findUsers.users"
                  :items-per-page="itemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:[`item.img`]="{ item }">
                    <v-avatar
                      :color="item.img ? '' : '#c4c4c4'"
                      size="36"
                    >
                      <img
                        v-if="item.img"
                        :src="item.img"
                      >
                      <v-icon
                        v-else
                        size="22"
                        style="height: inherit;"
                      >
                        mdi-account
                      </v-icon>
                    </v-avatar>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      :to="{ name: 'users', params: { id: item.id }}"
                      class="text-none"
                      depressed
                      height="36"
                      large
                    >
                      Подробнее
                    </v-btn>
                  </template>
                </v-data-table>
                <v-divider />

                <div class="text-center pt-3">
                  <v-pagination
                    v-model="page"
                    :length="usersData.findUsers.pages"
                    :total-visible="9"
                  />

                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 15]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="firstPage"
                  />
                </div>
              </template>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';

export default {
  components: {
    AppRow,
    AppNotification,
  },
  data() {
    return {
      searchText: '',
      searchTimeout: null,
      search: '',
      headers: [
        { text: '', value: 'img' },
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Имя:', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Роль:', value: 'role', class: 'white--text text-subtitle-2' },
        { text: 'Дата регистрации:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      page: 1,
      itemsPerPage: 5,
    };
  },
  watch: {
    searchText(value) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.firstPage();
        this.search = value;
      }, 500);
    },
  },
  methods: {
    firstPage() {
      this.page = 1;
    },
  },
};
</script>
